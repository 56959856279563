import SbEditable from "storyblok-react"
import dynamic from "next/dynamic"

// resolve Storyblok components to Next.js components
export const Components = {
  // Atoms
  button: dynamic(() => import("./atoms/StoryblokButton")),
  content: dynamic(() => import("./atoms/Content")),
  page_header: dynamic(() => import("./atoms/PageHeader")),
  cta_banner: dynamic(() => import("./atoms/CTABanner")),
  inline_image: dynamic(() => import("./atoms/InlineImage")),
  inline_pull_quote: dynamic(() => import("./atoms/PullQuote")),
  inline_video: dynamic(() => import("./atoms/InlineVideo")),
  inline_accordion: dynamic(() => import("./atoms/InlineAccordion")),
  page_divider: dynamic(() => import("./atoms/PageDivider")),
  two_column_list: dynamic(() => import("./atoms/TwoColumnList")),
  inline_team_members: dynamic(() => import("./atoms/InlineTeamMembers")),
  inline_iframe: dynamic(() => import("./atoms/InlineIframe")),
  jotform_embed: dynamic(() => import("./atoms/JotformEmbed")),
  inline_cards: dynamic(() => import("./atoms/InlineCards")),

  // Molecules
  faq: dynamic(() => import("./molecules/FAQSection")),
  featured_image: dynamic(() => import("./molecules/FeaturedImage")),
  image_and_content: dynamic(() => import("./molecules/ImageAndContent")),
  testimonial_carousel: dynamic(() => import("./molecules/Testimonials")),
  homepage_map_section: dynamic(() => import("./molecules/HomepageMapSection")),
  image_card_section: dynamic(() => import("./molecules/ImageCardSection")),
  image_and_text_hero: dynamic(() => import("./molecules/ImageAndTextHero")),
  page_header_with_image: dynamic(() => import("./molecules/PageHeaderWithImage")),
  card_section: dynamic(() => import("./molecules/CardSection")),
  location_links: dynamic(() => import("./molecules/LocationLinks")),

  // Organisms
  page: dynamic(() => import("./organisms/Page")),
}

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok?.component] !== "undefined") {
    const Component = Components[blok.component]
    // wrap with SbEditable for visual editing

    // Set an _editable value to avoid error in SbEditable
    if (blok._editable === null) {
      blok._editable = undefined
    }

    return (
      <SbEditable content={blok}>
        <Component blok={blok} />
      </SbEditable>
    )
  }

  // fallback if the component doesn't exist
  console.error(`Missing blok resolvers for ${blok?.component}:\n${JSON.stringify(blok)}`)
  return null
}

export default DynamicComponent
