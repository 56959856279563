import DynamicComponent from "components/DynamicComponent"
import Meta from "components/Meta"
import Testimonials from "components/DynamicComponent/molecules/Testimonials"
import { getPage } from "storyblok/api"
import { getHomepageTestimonials } from "storyblok/getTestimonials"

export default function Home({ story, testimonials, meta }) {
  return (
    <>
      <Meta info={meta} />
      <DynamicComponent blok={story?.content} />
      <Testimonials
        blok={{
          title: "From our patients",
          style: "dark",
          testimonials,
          component: "testimonial_carousel",
        }}
      />
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  // home is the default slug for the homepage in Storyblok, but we've aliased it to '/' here and in the visual editor.
  // This template would otherwise be identical to pages/[...slug.js] and therefore unnecessary
  const { page, globals } = await getPage("home", preview)
  const testimonials = await getHomepageTestimonials()

  return {
    props: {
      globals,
      story: page ?? null,
      testimonials,
      meta: page?.content?.twentysixty_seo ?? null,
      preview,
    },
  }
}
