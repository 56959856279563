import richText from "utilities/richText"

export default function PullQuote({ blok, style = "light" }) {
  switch (style) {
    case "light":
      return <LightTestimonial {...blok} />
    case "dark":
      return <DarkTestimonial {...blok} />
    default:
      return "Invalid pull quote/testimonial style"
  }
}

export function LightTestimonial({ quote, attribution, sectionTitle, slide = 1, total = 1 }) {
  return (
    <blockquote
      className="blue-quote w-full my-4 py-6 px-8 sm:px-12 bg-blue-light text-teal"
      aria-roledescription="slide"
      role="group"
      aria-label={`${sectionTitle ? `${sectionTitle}: ` : ""}${slide} of ${total}`}
    >
      <div className="prose-p:inline-block prose-p:font-primary prose-p:text-m1 prose-p:pb-4 last:prose-p:pb-0">
        {richText(quote)}
      </div>
      {attribution ? (
        <footer className="mt-2">
          <cite className="not-italic uppercase font-primary">– {attribution}</cite>
        </footer>
      ) : null}
    </blockquote>
  )
}

export function DarkTestimonial({ quote, attribution, title, sectionTitle, slide = 1, total = 1 }) {
  return (
    <blockquote
      className="py-4 lg:pt-12 px-6 lg:px-12 xl:px-32 text-white text-center"
      aria-roledescription="slide"
      role="group"
      aria-label={`${sectionTitle ? `${sectionTitle}: ` : ""}${slide} of ${total}`}
    >
      <h3 className="text-center uppercase text-m2 pb-2">{title}</h3>
      <div className="text-m1 light-blue-quote prose-p:font-primary prose-p:pb-2 relative lg:static prose-p:text-white">
        {richText(quote)}
      </div>
      {attribution ? (
        <footer>
          <cite className="text-m1 font-primary not-italic">- {attribution}</cite>
        </footer>
      ) : null}
    </blockquote>
  )
}
