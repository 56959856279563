import Script from "next/script"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import cn from "classnames"
import PullQuote from "components/DynamicComponent/atoms/PullQuote"
import { getPlainText } from "utilities/getPlainText"
import useWindowSize from "utilities/useWindowSize"
import Arrow from "public/assets/testimonial-arrow.svg"

export default function Testimonials({ blok }) {
  const lastIndex = blok.testimonials?.length - 1
  const [currentSlide, setSlide] = useState(0)
  const windowSize = useWindowSize()

  const prevSlide = () => {
    setSlide(currentSlide === 0 ? lastIndex : currentSlide - 1)
  }
  const nextSlide = () => {
    setSlide(currentSlide === lastIndex ? 0 : currentSlide + 1)
  }

  const [currentHeight, setCurrentHeight] = useState()

  useEffect(() => {
    setCurrentHeight(
      document.querySelector(
        `[aria-label="${blok.title ? `${blok.title}: ` : ""}${currentSlide + 1} of ${blok.testimonials?.length}"]`
      )?.clientHeight
    )
  }, [blok, currentSlide, currentHeight, windowSize])

  if (!blok?.testimonials || blok.testimonials.length === 0) {
    return null
  }

  const Wave = blok.style === "dark" ? dynamic(() => import("../../../public/assets/testimonial-wave.svg")) : () => null

  return (
    <>
      <section
        className={cn("testimonials px-8 py-12 relative overflow-hidden", {
          "bg-dark-testimonial bg-cover bg-no-repeat": blok.style === "dark",
        })}
        aria-roledescription="carousel"
        aria-label="Sonoran Spine testimonials"
      >
        <h2
          className={cn("text-center uppercase", {
            "text-m2 mb-12 relative mx-auto before:content-[''] before:h-[3px] before:w-full before:bg-blue before:absolute before:z-0 before:left-0 before:top-1/2 before:-translate-y-1/2 max-w-full block":
              blok.style === "light",
            "text-white text-m3 lg:text-l2 pb-4 sm:pb-8": blok.style === "dark",
          })}
        >
          <span
            className={cn("font-primary", {
              "bg-white block w-auto sm:w-max mx-auto sm:border-x-8 relative z-10 border-white max-w-[190px] sm:max-w-full":
                blok.style === "light",
            })}
          >
            {blok.title}
          </span>
        </h2>
        <div className="mx-auto max-w-screen-xl relative z-10">
          <Wave className="mx-auto mb-4 lg:mb-0" />
          <div className="max-w-full lg:max-w-3xl xl:max-w-4xl mx-auto">
            <div
              className="flex transition-all duration-700 gap-4 px-2"
              style={{ transform: `translateX(-${currentSlide * 100}%)`, maxHeight: `${currentHeight}px` }}
              aria-live="off"
            >
              {blok.testimonials.map((item, idx) => {
                const visible = idx === currentSlide
                return (
                  <div
                    className={cn(
                      "max-w-4xl min-w-full transform transition-all duration-500 relative h-full self-center block",
                      {
                        "translate-y-0 opacity-1": visible,
                        "translate-y-10 opacity-0": !visible,
                      }
                    )}
                    key={idx}
                  >
                    <PullQuote
                      style={blok.style}
                      blok={{ ...item, slide: idx + 1, total: blok.testimonials.length, sectionTitle: blok?.title }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          {blok.testimonials.length > 1 ? (
            <div className="flex gap-12 lg:gap-4 mt-8 lg:mt-0 justify-center">
              <button
                onClick={prevSlide}
                className={cn("lg:absolute lg:left-0", {
                  "lg:top-24": blok.style === "dark",
                  "lg:top-16": blok.style === "light",
                })}
                aria-label="Previous Slide"
              >
                <Arrow
                  className={cn("carousel-arrow rotate-180", {
                    "text-blue": blok.style === "dark",
                    "text-purple": blok.style === "light",
                  })}
                />
              </button>
              <button
                onClick={nextSlide}
                className={cn("lg:absolute lg:right-0", {
                  "lg:top-24": blok.style === "dark",
                  "lg:top-16": blok.style === "light",
                })}
                aria-label="Next Slide"
              >
                <Arrow
                  className={cn("carousel-arrow", {
                    "text-blue": blok.style === "dark",
                    "text-purple": blok.style === "light",
                  })}
                />
              </button>
            </div>
          ) : null}
        </div>
      </section>
      <Script type="application/ld+json" id="testimonial-schema">
        {`{
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Spinal Surgery",
            "description": "",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": ${blok.testimonials.length}
            },
            "review": [${blok.testimonials.map(
              (testimonial) => `
              {
                "@type": "Review",
                "name": ${testimonial.attribution ? `"${testimonial.attribution}"` : `"Valued Customer"`},
                "author": { "@type": "Person", "name": ${
                  testimonial.attribution ? `"${testimonial.attribution}"` : `"Valued Customer"`
                } },
                "reviewBody": "${getPlainText(testimonial.quote, { addNewlines: false })}",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "5"
                 }
              }`
            )}
          ]
        }`}
      </Script>
    </>
  )
}
